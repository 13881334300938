export const Bio = {
  name: "Dr. Rahul Suresh",
  roles: [
    "Data Scientist",
    "R&D Specialist",
    "PostDoctoral Researcher",
    "Computational Physicist",
  ],
  description:"I love delving into molecular quantum physics, exploring Density Functional Theory, adsorption, and 2D materials. My postdoc in Dublin combines AI and healthcare to revolutionize cancer diagnosis with machine learning.",
  github: "https://github.com/drrahulsuresh",
  // resume:"https://drive.google.com/file/d/1zBdwNvQIJ-jm0ERawCOwg810PpeAmTgH/view?usp=sharing",
  linkedin: "https://www.linkedin.com/in/drrahul/",
  insta: "",
  facebook: "",
};

export const skills = [
  {
    title: "Data Science",
    skills: [
      {
        name: "Data Analysis",
      },
      {
        name: "Machine Learning",
      },
      {
        name: "Deep Learning",
      },
      {
        name: "Data Cleaning and Preprocessing",
      }      
    ],
  },
  {
    title: "Molecular Dynamic Simulations",
    skills: [
      {
        name: "Gromacs",
      },
      {
        name: "Amber",
      },
      {
        name: "Charmm",
      },
      {
        name: "Lammps",
      },
    ],
  },
  {
    title: "Density Functional Theory",
    skills: [
      {
        name: "VASP",
      },
      {
        name: "Gaussian",
      },
      {
        name: "ORCA",
      },
      {
        name: "Siesta",
      },
    ],
  },
  {
    title: "Others",
    skills: [
      {
        name: "Aiida",
      },
      {
        name: "AFlow",
      },
      {
        name: "Python",
      },
      {
        name: "C++",
      }
    ],
  }
];

export const experiences = [
  {
    id: 1,
    img: "https://firebasestorage.googleapis.com/v0/b/drrahulsuresh-90dbb.appspot.com/o/TU_Dublin.webp?alt=media&token=32bd127a-ebe8-4bd7-8f45-8369a4d5405a",
    role: " Postdoctoral Researcher",
    company: "Technological University Dublin",
    date: "November 2023",
    desc: "Leveraging machine learning (ML) and deep learning (DL) techniques to enhance the identification, prognosis, and diagnosis of cancer. Creating pipelines, developing algorithms and tools to solve complex clinical problems using AI and data analytics.",
    skills: [
      "Machine Learning",
      "Deep Learning",
      "Data Analysis",
    ]
  },
  {
    id: 2,
    img: "https://firebasestorage.googleapis.com/v0/b/drrahulsuresh-90dbb.appspot.com/o/Siberian%20Federal%20University.jpeg?alt=media&token=7a546c92-44c7-43ec-b730-f33e82c59405",
    role: "Senior Researcher",
    company: "Siberian Federal University",
    date: "July 2021 - November 2023",
    desc: "Exfoliation of 2D materials from non-layered bulk materials using combined ML-DFT based high throughput calculations.Understanding the superatomic molecular orbitals in fullerene molecules for the applications of organic electronics.Electronic and transport properties of 1-D nanotubes of porphyrin.Development of 1D organic nanotube as an active electrode for solid-state applications.",
    skills: [
      "Computational Chemistry",
      "Materials Science",
      "Nanotechnology"
    ]
  },
  {
    id: 3,
    img: "https://firebasestorage.googleapis.com/v0/b/drrahulsuresh-90dbb.appspot.com/o/Sri_Shakthi.png?alt=media&token=6ad169a2-7b46-4f8f-9e4d-1cc756c7b583",
    role: "Assistant professor",
    company: "Srishakthi Institute of Engineering and Technology",
    date: "Feb 2021 -April 2021",
    desc: "Taught undergraduate students in the Department of Physics. Conducted laboratory experiments and guided students in their projects. Organized workshops and seminars on the latest trends in Physics.",
    skills: [
      "Physics",
      "Teaching"
    ],
  },
  {
    id: 4,
    img: "https://firebasestorage.googleapis.com/v0/b/drrahulsuresh-90dbb.appspot.com/o/Private_tutor.webp?alt=media&token=ffd84803-db25-4222-9686-99ef65fe4b41",
    role: "Private tutor",
    company: "Self-employed",
    date: "June 2017 - Dec 2018",
    desc: "Taught high school students in Physics and Mathematics. Conducted regular tests and provided feedback to students to help them improve their performance.",
    skills: [
      "Physics",
      "Teaching"
    ],
  },
];

export const education = [
  {
    id: 0,
    img: "https://firebasestorage.googleapis.com/v0/b/drrahulsuresh-90dbb.appspot.com/o/bharathiar-university.png?alt=media&token=8b62ba0c-5265-4426-871f-40d3a3be02d9",
    school: "Bharathiar University",
    date: "March 2021",
    desc:"Conducted density functional theory (DFT) simulations to validate porphyrin's efficacy as an adsorbent for toxic substances. Additionally, designed and synthesized 2-D porphyrin covalent organic frameworks (COFs) for application as electrodes in batteries.",
    degree: "Ph.D. (Molecular Quantum Physics)",
  },
  {
    id: 1,
    img: "https://firebasestorage.googleapis.com/v0/b/drrahulsuresh-90dbb.appspot.com/o/PSG.webp?alt=media&token=e80776ce-0c74-4abf-bd4d-637aeff3d054",
    school: "PSG College of Arts and Science",
    date: "May 2015",
    desc:"Attained a commendable 7.2 CGPA during the pursuit of a Master of Science in Physics at PSG College of Arts and Science, fostering a fervent interest in further academic endeavors, particularly in the pursuit of a Ph.D.",
    degree: "Master of Science (Physics)",
  },
  {
    id: 2,
    img: "https://firebasestorage.googleapis.com/v0/b/drrahulsuresh-90dbb.appspot.com/o/PSG.webp?alt=media&token=e80776ce-0c74-4abf-bd4d-637aeff3d054",
    school: "PSG College of Arts and Science",
    date: "May 2013",
    desc:"Completed a Bachelor of Science in Physics at PSG College of Arts and Science, achieving a 6.6 CGPA, which laid the foundation for a successful academic career in the field of physics and quantum mechanics.",
    degree: "Bachelor of Science (Physics)",
  },
];

export const projects = [
  {
    id: 11,
    title: "DecisionHub",
    date: "Jan 2024 - Dec 2023",
    description:
      "A Rule Builder application “Decision Hub” that empowers Business Analysts to create, save, and visualize decision strategies. Provide a no-code rule writing experience and visual representation to test these rules in real-time and observe the calculations at each step.",
    image:
      "https://github.com/rishavchanda/DecisionHub/raw/master/assets/testRule.jpg",
    tags: [
      "React Js",
      "PostgressSQL",
      "Node Js",
      "Express Js",
      "Redux",
      "React Flow",
    ],
    category: "web app",
    github: "https://",
    webapp: "https://",
  },
  {
    id: 9,
    title: "Trackify",
    date: "Jun 2023 - Jul 2023",
    description:
      "Trackify is a web application designed to streamline task management and enhance productivity in the workplace. It provides a user-friendly interface for employers to keep track of their employees' daily work activities and empowers employees to log their tasks efficiently. \nAdmin Credentials: # Email: testadmin@gmail.com #Password- 123@testadmin, Employee Credentials:	#Email: testemployee@gmail.com	#Password- 123@Testemployee",
    image:
      "https://user-images.githubusercontent.com/64485885/255202416-e1f89b04-2788-45b0-abc2-9dec616669e2.png",
    tags: [
      "Docker",
      "AWS",
      "DuckDNS",
      "Eslint",
      "Husky",
      "CI/CD",
      "React Js",
      "MongoDb",
      "Node Js",
      "Express Js",
      "Redux",
    ],
    category: "web app",
    github: "https:/",
    webapp: "https:/",
  },
  {
    id: 10,
    title: "Job Finding App",
    date: "Jun 2023 - Jul 2023",
    description:
      "A Job Finding App made with React Native, Axios. Users can search for any job coming from API and apply there.",
    image:
      "https://user-images.githubusercontent.com/64485885/255237090-cf798a2c-1b41-4bb7-b904-b5353a1f08e8.png",
    tags: ["React Native", "JavaScript", "Axios"],
    category: "android app",
    github: "https://github.com/",
    webapp: "https://github.com/",
  },
];

export const TimeLineData = [
  { year: 2017, text: "Started my journey" },
  { year: 2018, text: "Worked as a freelance developer" },
  { year: 2019, text: "Founded JavaScript Mastery" },
  { year: 2020, text: "Shared my projects with the world" },
  { year: 2021, text: "Started my own platform" },
];
